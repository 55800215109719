var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c("div", { staticClass: "index-conent" }, [
        _c(
          "div",
          { staticClass: "conetnt-left" },
          [
            _c("EmployeeSituation", { ref: "situation" }),
            _c(
              "div",
              { staticClass: "company-affair" },
              [_c("CompanyAffair"), _c("ScheduleList")],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "conetnt-right" },
          [_c("ToolInlet"), _c("ToDoCalendar")],
          1
        ),
      ]),
      _c("TenantModal", { ref: "TenantModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }