// 薪酬-奖金
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取上月人力总成本 实发工资 应发工资
export async function getLastMonthSalary(params) {
  return request(`${WAGE}/salaryCalc/getLastMonthSalary`, METHOD.GET, params)
}

// 获取薪酬集合
export async function getSalaryCalcList(params) {
  return request(`${WAGE}/salaryCalc/getSalaryCalcList`, METHOD.GET, params)
}
